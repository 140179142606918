.contact-details{
  display: flex;
  align-items: baseline;
}
.whatsapp{
  align-items: baseline;
}

.ab{
  float: right;
}
.right{
  align-items: baseline;
}
ul li a {
  text-decoration: none;
  color: #0095da;
}
ul li{
  /* text-decoration: none; */
  list-style: none;
  margin-top: 2px;
}


button{
  background-color: #0095da !important;
}
.txt-clr{
color: #fff !important;
font-weight: 500 !important;
}
.txt-color-secndry{
  color:#5a514f !important
}
.number{
  color: #fff !important;
}
.welcome{
  margin-top: 9rem;
}
.welcome h1{
  font-weight: 500;
    /* line-height: 32px; */
    font-size: 24px;
}
.welcom-main-pragraph{
  font-weight: 500;
    /* line-height: 32px; */
    font-size: 23px;
    line-height: 1.8rem;
}
.welcom-support-pragraph{
  font-weight: 300;
    /* line-height: 32px; */
    font-size: 18px;
    line-height: 1.8rem;
    font-style: italic;
}
.services{
  background-color: #eee;
  padding: 6rem 0rem;
}
.shadow{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.title{
  text-align: center;
 
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.title p{
  font-weight: 600;
  font-size: 1.3rem;
  color:red
}

.apologize{
  background-color: skyblue;
  padding: 5rem 0rem;
  color: #fff;
}
.float-lft{
  overflow: hidden;
  float: right;
}
.custom-container{
  padding-left: 12rem;
  padding-right: 12rem;
  
}
.Background{
  background-color: #0095da;
  color: #fff !important;
  /* line-height: 5px; */
  line-height:19px;
  padding-bottom: 0.5rem;
}
.Background2{
  background-color: #0095da;
  color: #fff !important;
  /* line-height: 5px; */
  line-height:19px;
  padding-bottom: 0.5rem;
}
@media only screen and (max-width: 600px) {
  .d-n {
  display: none;
  }
}
.nav-link:hover {
color: #0095da !important ;
}
.contactusform1{
  /* padding: 5rem 0rem; */
  /* background-color: #0095da; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
body{
  font-family: Roboto;
}
